import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        art_logo_zelene: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "logo_zelene" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="portfolio">
        <Container>
          <Grid>
            <Art>
              <Img fluid={data.art_logo_zelene.childImageSharp.fluid} />
            </Art>
            {/* Nově přidaná část pro Instagram */}
            <Instagram>
              <script src="https://cdn.lightwidget.com/widgets/lightwidget.js"></script>
              <iframe
                src="//lightwidget.com/widgets/9b445a70fb1852e69f3cbb0bfb108ccc.html"
                scrolling="no"
                allowtransparency="true"
                className="lightwidget-widget"
                style={{
                  width: '100%',
                  height: '50rem',
                  border: 0,
                  overflow: 'hidden',
                }}
              ></iframe>
            </Instagram>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  ${props =>
    props.inverse &&
    `
      ${Art} {
        order: 2;
      }
  `}
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

const Instagram = styled.div`
  width: 100%;
  height: 50rem;

  @media (max-width: ${props => props.theme.screen.md}) {
    height: 20rem;
  }
`;

export default About;

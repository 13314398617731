import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import { Container } from '@components/global';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        art_pot: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "customers_pot" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1960) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <FooterWrapper>
        <StyledContainer>
          <Copyright>
            <span>
              Tereza Kuřová <DesktopOnly> | </DesktopOnly>{' '}
              <MobileOnly>
                <br />
              </MobileOnly>
              <a href="https://www.euforika.eu">www.euforika.eu</a>{' '}
              <MobileOnly>
                <br />
              </MobileOnly>
              <DesktopOnly> | </DesktopOnly>
              <a href="tel:+420739625083">+420 739 625 083</a>{' '}
              <DesktopOnly> | </DesktopOnly>{' '}
              <MobileOnly>
                <br />
              </MobileOnly>
              <a href="https://www.instagram.com/eufori.ka/" target="_blank">
                @eufori.ka
              </a>
            </span>
          </Copyright>
        </StyledContainer>
      </FooterWrapper>
    )}
  />
);

const FooterWrapper = styled.footer`
  background-color: #0000;
  padding: 32px 0;
  text-align: center; /* Zarovnání textu do středu */
  width: 100%; /* Nastavení na 100% šířky */
`;

const Copyright = styled.div`
  font-family: 'Inter', sans-serif; /* Nastavení stylu písma na Inter regular */
  ${props =>
    props.theme.font_size.small}; /* Nastavení velikosti písma na 24px */
  color: #f5c19e; /* Změna barvy písma */

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const StyledContainer = styled(Container)`
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Nastavení na 100% šířky */
`;

const DesktopOnly = styled.span`
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: none;
  }
`;

const MobileOnly = styled.span`
  @media (min-width: ${props => props.theme.screen.sm}) {
    display: none;
  }
`;

export default Footer;

import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const Services = () => (
  <StaticQuery
    query={graphql`
      query {
        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "fast" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "learn_yourself" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "ideas" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="naše služby">
        <Container>
          <Grid>
            <div>
              <p>
                Veškeré květiny či dekorace jsou připravovány podle přání
                zákazníků. Řezané květiny jsou dováženy až 5x týdně. Zaručujeme
                Vám tedy jejich čerstvost a kvalitu.
              </p>
              <br />
              <h2>Nabízíme:</h2>
              <li>vázané dárkové kytice pro každou příležitost. </li>
              <li>flowerboxy</li>
              <li>sezónní věnce na dveře</li>
              <li>adventní věnce a svícny</li>
              <br />

              <h3>Svatební vazba a servis</h3>
              <li>svatební kytice</li>
              <li>náramky, věnečky</li>
              <li>slavobrány</li>
              <li>květinové výzdoby vašeho svatebního místa</li>
              <br />
              <h3>Společenské akce a oslavy</h3>
              <li>konzultace, dovoz, aranžování</li>
              <li>realizace květinových výzdob</li>
              <li>maturitní plesy</li>
            </div>
            <Art>
              <Img fluid={data.art_fast.childImageSharp.fluid} />
            </Art>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: left;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }
  li {
    list-style: none;
    margin-left: 0;
    padding-left: 1em;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default Services;

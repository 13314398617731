import React, { Fragment } from 'react';

import './style.css';

class Form extends React.Component {
  static defaultProps = {
    name: 'Poptávkový formulář',
    subject: '', // volitelný předmět notifikačního emailu
    successMessage: 'Děkujeme! Odpovíme Vám hned, jak to bude možné.',
    errorMessage:
      'Vyskytl se problém, Vaše zpráva nebyla odeslána. Prosím kontaktujte nás na e-mailovou adresu poulovamarketa@gmail.com',
  };

  state = {
    alert: '',
    disabled: false,
  };

  handleSubmit = async e => {
    e.preventDefault();
    if (this.state.disabled) return;

    const form = e.target;

    try {
      const response = await fetch(form.action, {
        method: 'POST',
        body: new FormData(form),
      });

      if (response.ok) {
        form.reset();
        this.setState({
          alert: this.props.successMessage,
          disabled: false,
        });
      } else {
        throw new Error('Chyba sítě');
      }
    } catch (err) {
      console.error(err);
      this.setState({
        disabled: false,
        alert: this.props.errorMessage,
      });
    }
  };

  render() {
    const { name, subject } = this.props;

    return (
      <Fragment>
        <form
          className="Form"
          name={name}
          onSubmit={this.handleSubmit}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          {this.state.alert && (
            <div className="Form--Alert">{this.state.alert}</div>
          )}
          <label className="Form--Label">
            <input
              className="Form--Input Form--InputText"
              type="text"
              placeholder="Jméno"
              name="firstname"
              required
            />
          </label>
          <label className="Form--Label">
            <input
              className="Form--Input Form--InputText"
              type="text"
              placeholder="Příjmení"
              name="lastname"
              required
            />
          </label>
          <label className="Form--Label">
            <input
              className="Form--Input Form--InputText"
              type="email"
              placeholder="Email"
              name="emailAddress"
              required
            />
          </label>
          <label className="Form--Label">
            <textarea
              className="Form--Input Form--Textarea Form--InputText"
              placeholder="Zpráva"
              name="message"
              rows="6"
              required
            />
          </label>
          {!!subject && <input type="hidden" name="subject" value={subject} />}
          <input type="hidden" name="form-name" value={name} />
          <input
            className="Button Form--SubmitButton"
            type="submit"
            value="Odeslat"
            disabled={this.state.disabled}
          />
        </form>
      </Fragment>
    );
  }
}

export default Form;

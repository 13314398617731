import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import './style.css';

import { Container } from '@components/global';

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        art_main_picture: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "main_picture" }
        ) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        art_logo: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "logo" }
        ) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        art_main_background: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "main_background" }
        ) {
          publicURL
        }
      }
    `}
    render={data => (
      <HeaderWrapper backgroundURL={data.art_main_background.publicURL}>
        <Container>
          <Grid>
            <LogoImage
              fluid={data.art_logo.childImageSharp.fluid}
              alt="Euforika - Flower design | Events | Photography" // Přidaný alt text pro logo
            />
            <CenteredImage
              fluid={data.art_main_picture.childImageSharp.fluid}
              alt="Coming soon" // Přidaný alt text pro hlavní obrázek
            />
          </Grid>
        </Container>
      </HeaderWrapper>
    )}
  />
);

const HeaderWrapper = styled.header`
  background: url(${props => props.backgroundURL}) no-repeat center center;
  background-size: cover;
  padding-top: 0;
  height: 100vh; /* Nastavení výšky na 100% celé obrazovky */
`;

const CenteredImage = styled(Img)`
  width: 100%;
  height: 100%;
  margin: auto; /* Zarovnání do středu */
`;

const LogoImage = styled(Img)`
  margin-top: 20rem;
  width: 30%;
  height: 100%;
  margin: auto; /* Zarovnání do středu */
  margin: 100px; /* Mezera pod obrázkem logo */

  @media (max-width: 768px) {
    width: 60%; /* Šířka obrázku pro mobilní telefony */
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default Header;
